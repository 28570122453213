import Cover from "./Layouts/cover";
import Details from "./Layouts/form";

function Submission() {

  return (
    <>
    <Cover></Cover>
    <Details></Details>
    </>
  );
}

export default Submission;