import React, { useEffect, useState } from 'react';
import MKBox from 'components/MKBox';
import PhotoFrame from 'assets/images/project/photo_frame1.png';
import CastingLogo from 'assets/images/project/cb_no_bg.png';

const GridLayout = ({ actorImages }) => {
  const [images, setImages] = useState({ cover: null, randomImages: [] });
  const top = [1, 15, 60, -7, 60, 28, -5, 60];
  const left = [-3, 20, 8, 40, 35, 58, 78, 80];

  useEffect(() => {
    if (actorImages && actorImages.length > 0) {
      const numCount = 8;
      const maxNumber = actorImages.length -1;
      const newRandomImages = [];
      let coverImage = null;
      let count = 1;
      let randomNumber = 1;
      const usedIndices = new Set(); 
      

      while (newRandomImages.length < numCount) {
        randomNumber = Math.floor(Math.random() * (maxNumber + 1));

        if(maxNumber >= 8 && usedIndices.has(randomNumber)){
          continue;
        }
        usedIndices.add(randomNumber);
        const image = actorImages[randomNumber]?.replace(/\+/g, '%20');
        const img = process.env.REACT_APP_IMG_URL + decodeURIComponent(image);
        
        if (count === 1) {
          coverImage = img;
        } else {
          newRandomImages.push({key: randomNumber, value: img});
        }
        count++;
      }
      setImages({ cover: coverImage, randomImages: newRandomImages });
    }
  }, [actorImages]);
  
  const { cover, randomImages } = images;

  return (
    <MKBox className="profileContainer">
        {cover && (
          <MKBox
            sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              backgroundImage: `url("${cover}")`,
              backgroundSize: 'cover',
              backgroundPosition: 'top center',
              opacity: 0.5,
              zIndex: -1,
            }}
          />
        )}
        {randomImages.map((image, index) => (
          <MKBox
            loading="lazy"
            className="profileImage"
            key={index}
            id={index}
            sx={{
              backgroundImage: `url("${image.value}")`,
              backgroundSize: 'cover',
              aspectRatio: '3/4',
              width: '18%',
              height: 'auto',
              transform: `rotateZ(${Math.random() * 20 - 10}deg)`,
              position: 'absolute',
              top: `${top[index]}%`,
              left: `${left[index]}%`,
              boxShadow: '0 7px 20px 5px #00000088',
              backgroundPosition: 'center !important',
            }}
          >
            <MKBox
              key={index}
              component="img"
              src={PhotoFrame}
              sx={{
                mb: { lg: -1, md: -1, xs: -2 },
                width: '100%',
                height: '100%',
                objectFit: 'cover',
                boxShadow: '0 7px 20px 5px #00000088',
              }}
            />
            <MKBox
              sx={{
                position: 'absolute',
                bottom: { xs: -4, s: -4, md: -2, lg: 5 },
                left: '10%',
                backgroundImage: `url(${CastingLogo})`,
              }}
            >
              <img src={CastingLogo} width="92%" className="cardLogo" />
            </MKBox>
          </MKBox>
        ))}
      </MKBox>
  );
};

export default GridLayout;
