import React, { useState } from "react";
import { FormControl, InputLabel, Select, MenuItem, Container, IconButton, Tooltip } from "@mui/material";
import Grid from "@mui/material/Grid";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Formik, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { submissionSubmit } from 'project/APIs/apis';
import { toastSuccess, toastError } from "shared libraries/tools";
import InfoIcon from '@mui/icons-material/Info';

// Define validation schema
const validationSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  dob: Yup.date().required("Date of birth is required"),
  mobile_number: Yup.string().required("Mobile number is required"),
  email: Yup.string().email("Invalid email address").required("Email is required"),
  gender: Yup.string().required("Gender is required"),
  height: Yup.string().required("Height is required"),
  vitals: Yup.string().required("Vitals Measurements are required"),
  instagramProfile: Yup.string().required("Instagram profile link is required").url("Invalid URL"),
  biography: Yup.string().required("Biography is required"),
});

// Custom file validation
const validateFiles = (values) => {
  const errors = {};
  if (!values.profileImage) {
    errors.profileImage = "Profile image is required";
  } else if (!values.profileImage.type.startsWith('image/')) {
    errors.profileImage = "Profile image must be an image file";
  }
  if (!values.images || values.images.length === 0) {
    errors.images = "At least one image is required";
  } else {
    values.images.forEach((file, index) => {
      if (!file.type.startsWith('image/')) {
        errors.images = `File ${index + 1} is not an image`;
      }
    });
  }
  return errors;
};

function Form() {
  const [profileLabel, setProfileLabel] = useState("Profile Image *");
  const [imagesLabel, setImagesLabel] = useState("Images *");
  const heightOptions = [];
  let message = "Something went wrong";

  for (let feet = 1; feet <= 8; feet++) {
    for (let inches = 0; inches <= 11; inches++) {
      heightOptions.push({ key: `${feet}Ft ${inches}in`, value: `${feet}.${inches}` });
    }
  }

  return (
    <MKBox component="section" py={12} style={{ backgroundColor: "white", boxShadow: '0px 2px 15px 0px rgba(0, 0, 0, 0.5)' }}>
      <Container>
        <Grid container item justifyContent="center" xs={10} lg={7} mx="auto" textAlign="center">
          <MKTypography variant="h3" mb={1}>
            Submission Form
          </MKTypography>
        </Grid>
        <Grid container item xs={12} lg={12} sx={{ mx: "auto" }}>
          <Formik
            initialValues={{
              name: '',
              email: '',
              dob: '',
              mobile_number: '',
              gender: 'female',
              height: '1.0',
              vitals: '',
              instagramProfile: '',
              profileImage: null,
              images: [],
              biography: '',
            }}
            validationSchema={validationSchema}
            validate={validateFiles}
            onSubmit={async (values, { setSubmitting, resetForm }) => {

              try {
                const formData = new FormData();
                formData.append('name', values.name);
                formData.append('email', values.email);
                formData.append('dob', values.dob);
                formData.append('mobile_number', values.mobile_number);
                formData.append('gender', values.gender);
                formData.append('height', values.height);
                formData.append('vitals', values.vitals);
                formData.append('instagramProfile', values.instagramProfile);
                formData.append('biography', values.biography);
                formData.append('profileImage', values.profileImage);
                values.images.forEach(file => {
                  formData.append('images[]', file);
                });
                
                const response = await submissionSubmit(formData);
                if (response.data.success) {
                  toastSuccess(response.data.message);
                  resetForm();
                  setProfileLabel('Profile Image *');
                  setImagesLabel('Images *')
                } else {
                  message = response.data.message || message;
                  toastError(message);
                }
              } catch (error) {
                message = error.response?.data?.message || message;
                toastError(message);
              }
              setSubmitting(false);
            }}
          >
            {({ setFieldValue, values, handleSubmit, isSubmitting }) => (
              <MKBox width="100%" component="form" noValidate onSubmit={handleSubmit} encType="multipart/form-data">
                <MKBox p={3}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={3}>
                      <Field
                        name="name"
                        as={MKInput}
                        variant="outlined"
                        type="text"
                        label="Name *"
                        fullWidth
                        sx={{ height: 48 }}
                      />
                      <ErrorMessage className="submissionError" name="name" component="div" />
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <Field
                        name="email"
                        as={MKInput}
                        variant="outlined"
                        type="email"
                        label="Email Address *"
                        fullWidth
                        sx={{ height: 48 }}
                      />
                      <ErrorMessage className="submissionError" name="email" component="div" />
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <Field
                        name="mobile_number"
                        as={MKInput}
                        variant="outlined"
                        type="text"
                        label="Phone Number *"
                        fullWidth
                        sx={{ height: 48 }}
                      />
                      <ErrorMessage className="submissionError" name="mobile_number" component="div" />
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <Field
                        name="dob"
                        as={MKInput}
                        variant="outlined"
                        type="date"
                        fullWidth
                        sx={{ height: 48 }}
                      />
                      <ErrorMessage className="submissionError" name="dob" component="div" />
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <FormControl fullWidth variant="outlined">
                        <InputLabel id="gender-label">Gender *</InputLabel>
                        <Field
                          as={Select}
                          labelId="gender-label"
                          name="gender"
                          label="Gender *"
                          IconComponent={ExpandMoreIcon}
                          sx={{ 
                            height: 48,
                            '& .MuiSelect-icon':{
                              display: 'block',
                            } 
                          }}
                        >
                          <MenuItem value="female">Female</MenuItem>
                          <MenuItem value="male">Male</MenuItem>
                        </Field>
                        <ErrorMessage className="submissionError" name="gender" component="div" />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <FormControl fullWidth variant="outlined">
                        <InputLabel id="height-label">Height *</InputLabel>
                        <Field
                          as={Select}
                          labelId="height-label"
                          name="height"
                          label="Height *"
                          IconComponent={ExpandMoreIcon}
                          sx={{ 
                            height: 48,
                            '& .MuiSelect-icon':{
                              display: 'block',
                            } 
                          }}
                        >
                          {heightOptions.map((option, index) => (
                            <MenuItem key={index} value={option.value}>
                              {option.key}
                            </MenuItem>
                          ))}
                        </Field>
                        <ErrorMessage className="submissionError" name="height" component="div" />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={3} container alignItems="center">
                      <Grid item xs={11}>
                        <Field
                          name="vitals"
                          as={MKInput}
                          variant="outlined"
                          type="text"
                          label="Vitals Measurements *"
                          fullWidth
                          sx={{ height: 48 }}
                        />
                        <ErrorMessage className="submissionError" name="vitals" component="div" />
                      </Grid>
                      <Grid item xs={1}>
                        <Tooltip title="Value should be in the format: bust-waist-hips (e.g., 32-26-30).">
                          <IconButton size="small">
                            <InfoIcon />
                          </IconButton>
                        </Tooltip>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <Field
                        name="instagramProfile"
                        as={MKInput}
                        variant="outlined"
                        type="url"
                        label="Instagram Profile Link"
                        fullWidth
                        sx={{ height: 48 }}
                      />
                      <ErrorMessage className="submissionError" name="instagramProfile" component="div" />
                    </Grid>
                    <Grid item xs={12} md={6} lg={6} sx={{ ml: 3, borderBottom: "1px solid #d2d6da !important", color: "#7b809a", fontSize: "0.875rem" }}>
                      <input
                        style={{ display: "none" }}
                        type="file"
                        name="profileImage"
                        id="profile_image"
                        onChange={(event) => {
                          const { name, files } = event.target;
                          if (files.length > 0) {
                            setFieldValue(name, files[0]);
                            setProfileLabel(files[0].name);
                          }
                        }}
                      />
                      <span>{profileLabel}</span>
                      <label htmlFor="profile_image">
                        <MKButton variant="gradient" color="dark" size="small" component="span" sx={{ float: "right" }}>
                          Choose Files
                        </MKButton>
                      </label>
                      <ErrorMessage className="submissionError" name="profileImage" component="div" />
                    </Grid>
                    <Grid item xs={12} md={5} lg={5} sx={{ ml: 3, borderBottom: "1px solid #d2d6da !important", color: "#7b809a", fontSize: "0.875rem" }}>
                      <input
                        style={{ display: "none" }}
                        type="file"
                        name="images"
                        multiple
                        id="images"
                        onChange={(event) => {
                          const { name, files } = event.target;
                          setFieldValue(name, Array.from(files));
                          setImagesLabel(`${files.length} files selected`);
                        }}
                      />
                      <span>{imagesLabel}</span>
                      <label htmlFor="images">
                        <MKButton variant="gradient" color="dark" size="small" component="span" sx={{ float: "right" }}>
                          Choose Files
                        </MKButton>
                      </label>
                      <ErrorMessage className="submissionError" name="images" component="div" />
                    </Grid>
                    <Grid item xs={12}>
                      <Field
                        name="biography"
                        as={MKInput}
                        variant="outlined"
                        type="text"
                        label="Biography *"
                        multiline
                        fullWidth
                        rows={6}
                      />
                      <ErrorMessage className="submissionError" name="biography" component="div" />
                    </Grid>
                  </Grid>
                  <Grid container item justifyContent="center" xs={12} my={2}>
                    <MKButton type="submit" variant="gradient" color="success" sx={{ width: { xs: '100%', lg: 'auto' } }}>
                      Submit
                    </MKButton>
                  </Grid>
                </MKBox>
              </MKBox>
            )}
          </Formik>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Form;
