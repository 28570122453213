  import MKBox from "components/MKBox";
  import bgImage from "assets/images/project/contact_3.jpg";
  import MKTypography from "components/MKTypography";

  function Cover() {
    return (
      <>    
        <MKBox className="bannerContainer bannerContactUs" 
          sx={{
            backgroundImage: `url(${bgImage})`,
          }}
        >
          <MKTypography className="bannerText bannerSlashText">
            /
          </MKTypography>
          <MKTypography className="bannerText bannerRevealText">
            <MKTypography className="bannerText bannerContactUsText">
              CONTACT US
            </MKTypography>
          </MKTypography>
        </MKBox>
      </>      
    );
  }

  export default Cover;
