import React from 'react';
import MKBox from 'components/MKBox';
import bgImage from 'assets/images/project/cb_main_banner_1.png';
import { css } from '@emotion/react';
import NavBar from 'project/Template/navbar';

// Define the keyframe animation for the fade-in effect globally
const globalStyles = css`
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;

function Banner() {
  return (  
    <div
      css={globalStyles} // Apply the global styles
    >
      <MKBox
        sx={{
          position: 'static',
          minHeight: {
            xs: '20vh',
            sm: '60vh',
            md: '75vh',
            lg: '85vh',
            xl: '75vh'
          },
          width: '100%',
          backgroundColor: 'transparent',
          color: '#344767',
          borderRadius: 0,
          //boxShadow: 'none',
          display: 'grid',
          placeItems: 'center',
          overflow: 'hidden', // Ensure padding doesn't cause overflow

          // Apply background image directly to MKBox
          backgroundImage: `url(${bgImage})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          padding: '10px', // Padding around the content

          // Apply the fade-in animation directly to MKBox
          animation: 'fadeIn 3s ease-in-out',
          boxShadow: '0px 2px 10px 0px rgba(0, 0, 0, 0.2)',
          // Additional responsive styles
          '@media (max-width: 600px)': {
            minHeight: '20vh',
          },
          '@media (min-width: 600px) and (max-width: 800px)': {
            minHeight: '30vh',
          },
          '@media (min-width: 801px) and (max-width: 840px)': {
            minHeight: '30vh',
          },
          '@media (min-width: 841px) and (max-width: 960px)': {
            minHeight: '100vh',
          },
          '@media (min-width: 960px) and (max-width: 1200px)': {
            minHeight: '60vh',
          },
          '@media (min-width: 1201px)': {
            minHeight: '60vh',
          },
        }}
      >
        {/* Add any additional content or components inside MKBox */}
      </MKBox>
    </div>
  );
}

export default Banner;
