import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import theme from "assets/theme";
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Footer from "project/Template/footer";
import Home from "project/HomePage/home";
import Actors from "project/Actors";
import Profile  from "project/Actors/Layout/profile";
import NavBar from "project/Template/navbar";
import ContactUs from "project/ContactUs";
import Submission from "project/Submissions";
import WorkProfile from "project/WorkProfile/index";
import { Navigate } from "react-router-dom";

export default function App() {
  return (
    <ThemeProvider theme={theme} >
      <CssBaseline />
      <Router>
        <NavBar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/actors" element={<Actors />} />
          <Route path="/actors/profile/:name/:id" element={<Profile />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/submission" element={<Submission />} />
          <Route path="/work-profile/:name" element={<WorkProfile />} />
          <Route path="/work-profile/*" element={<Navigate to="/" replace />} />
          <Route path="/actors/profile/*" element={<Navigate to="/actors" replace />} />
        </Routes>
        <Footer />
      </Router>
    </ThemeProvider>
  );
}
