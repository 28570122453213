import React from 'react';
import './imgGallery.css'; // Import the CSS file
import Grid from "@mui/material/Grid";
import { Container, keyframes } from "@mui/material";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import Icon from "@mui/material/Icon";
import MKButton from 'components/MKButton';
import 'project/index.css';
import { actorimagesDownload } from 'project/APIs/apis';
import { toastError, toastSuccess} from 'shared libraries/tools';

const ImageGallery = ({actorImages, id }) => {  

    const downloadImages = async () => {
      try{
        const response = await actorimagesDownload(id);

        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;

        const contentDisposition = response.headers['content-disposition'];
        let fileName = 'download.zip'; // Fallback filename

        if (contentDisposition) {
          const fileNameMatch = contentDisposition.match(/filename=(.+)/);
          if (fileNameMatch.length === 2) {
            fileName = fileNameMatch[1];
          }
        }

        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
        link.remove();
        toastSuccess('File downloaded');
      }catch(error){
        toastError('Failed to download file');
      }
    };

  return (
    <MKBox component="section" py={3} style={{ backgroundColor: "white", boxShadow: '0px 2px 15px 0px rgba(0, 0, 0, 0.5)' }}>
      <Container>
        {/* <Grid container item xs={12} lg={12}  justifyContent="center" textAlign="center">
            <MKTypography variant="h2" mb={1}>
                GALLERY
            </MKTypography>
        </Grid> */}
        <Grid container item xs={12} lg={12} mb={1} borderBottom={{lg: "2px solid #FA6D15"}} style={{ display: 'flex', justifyContent: 'space-between' }}>
            <MKTypography className="commonTextColor" variant="h2" textGradient>
                GALLERY
            </MKTypography>
    
                <MKButton color="success" onClick={downloadImages} sx={{ marginBottom:"5px" }}>
                    DOWNLOAD <Icon>download</Icon>
                </MKButton>
        </Grid>
        {actorImages && (
          <Grid container spacing={3} className="">
            {actorImages.map((src, index) => {
              const correctedSrc = src.replace(/\+/g, '%20');
              let decodedSrc = process.env.REACT_APP_IMG_URL + decodeURIComponent(correctedSrc);
              return (
                <Grid item xs={6} sm={6} md={3} lg={3} pt={0} key={index} className="carousel-item">
                    <MKBox 
                        component="img" 
                        src={decodedSrc} 
                        alt={`Image ${index + 1}`} 
                        className="galleryImages carousel-item__img"
                        loading="lazy"
                    />
                </Grid>
              );
            })}
          </Grid>
        )}
      </Container>
    </MKBox>
  );
};

export default ImageGallery;
