import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton";
import ContactUs from "assets/images/project/contact_us.jpg";

function FeaturesOne() {
  return (
    <MKBox component="section" py={{ xs: 3, md: 12 }} >
        <Container>
            <Grid container alignItems="center">
                <Grid item xs={12} lg={6}>
                    <MKBox className="sectionImg"
                        component="img" 
                        src={ContactUs} 
                        alt="contact us logo" 
                    />    
                </Grid>
                <Grid item xs={12} lg={5} sx={{ ml: { xs: 0, lg: "auto" }, mt: { xs: 6, lg: 0 } }}>
                    <MKTypography variant="h3" my={1}>
                        CONTACT US
                    </MKTypography>
                    <MKTypography variant="body2" color="text" mb={2}>
                        Have a query? Contact us to discuss your needs and find out how we can help. Reach out via our contact form, email, or phone, and we’ll get back to you promptly.
                    </MKTypography>
                    <MKButton className="sectionBtn"
                        component="a"
                        href="/contact-us"
                        variant="outlined" 
                        color="secondary" 
                        size="large" 
                    >
                        click Here <Icon sx={{ ml: 1 }}>arrow_forward</Icon>
                    </MKButton>                
                </Grid>
            </Grid>
        </Container>
    </MKBox>
  );
}

export default FeaturesOne;