import MKBox from "components/MKBox";
import bgImage from "assets/images/project/work_profile_1.png";
import MKTypography from "components/MKTypography";

function Cover() {
  return (
    <>    
      <MKBox className="bannerContainer bannerWorkProfile"  
        sx={{
          backgroundImage: `url(${bgImage})`,
        }}
      >
        <MKTypography className="bannerText bannerSlashText">
          /
        </MKTypography>
        <MKTypography className="bannerText bannerRevealText">
          <MKTypography className="bannerText bannerWorkProfileText">
            WORK PROFILE
          </MKTypography>
        </MKTypography>
      </MKBox>
    </>         
  );
}

export default Cover;
