import PropTypes from "prop-types";
import Icon from "@mui/material/Icon";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

function CardInfo({ color = "info", icon, title, description = "", direction = 'left', small = false }) {
  return (
    <MKBox lineHeight={1} p={direction === "center" ? 2 : 0} textAlign={direction}>
      {typeof icon === "string" ? (
        <MKTypography
          display="block"
          variant={direction === "center" ? "h2" : "h3"}
          color={color}
          textGradient
        >
          <Icon>{icon}</Icon>
        </MKTypography>
      ) : (
        icon
      )}
      <MKTypography
        display="block"
        variant="h5"  // Ensure a valid value
        fontWeight="bold"
        mt={direction === "center" ? 1 : 0}
        mb={0.5}
      >
        {title}
      </MKTypography>
      {description && (
        <MKTypography
          display="block"
          variant={small ? "button" : "body2"}
          color="text"  // Ensure this is a valid value
          pr={direction === "left" ? 6 : 0}
          pl={direction === "right" ? 6 : 0}
        >
          {description}
        </MKTypography>
      )}
    </MKBox>
  );
}

CardInfo.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "light",
    "dark",
  ]),
  icon: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  direction: PropTypes.oneOf(["left", "right", "center"]),
  small: PropTypes.bool,
};

export default CardInfo;
