import Cover from "project/Actors/Layout/cover";
import Categories from "project/Actors/Layout/categories";
import { Helmet } from 'react-helmet';
import { useState, useEffect } from "react";

function Actors() {
  const [currentTitle, setCurrentTitle] = useState('');

  useEffect(() => {
    const title = document.title;
    setCurrentTitle(title);
  }, []);

  return (
    <>
      <Cover></Cover>
      <Categories></Categories>
    </>
  );
}

export default Actors;
