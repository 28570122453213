import MKBox from "components/MKBox";
import bgImage from "assets/images/project/submission.jpg";
import MKTypography from "components/MKTypography";
import { css, keyframes } from '@emotion/react';

// Define keyframe animations
const hideSlash = keyframes`
  0% { opacity: 0; }
  20% { opacity: 1; }
  72% { opacity: 1; margin-left: -45px;}
  100% { opacity: 0; margin-left:-70px;}
`;

const slidein = keyframes`
  0% { margin-left: -800px; }
  20% { margin-left: -800px; }
  38% { margin-left: 0;  }
  100% { margin-left: 0;  }
`;

const reveal = keyframes`
  0% { opacity: 0; width: 0; }
  15% { opacity: 0.9; width: 0; }
  30% { width: auto; }
  80% { opacity: 0.8; }
  95% { opacity: 0.8; }
  100% { opacity: 0.8; width: auto; }
`;

const circle = keyframes`
  0% {
    opacity: 0; 
    clip-path: circle(50%);
  }
  50% { 
    clip-path: circle(30%);
  }
  100% { 
    opacity: .9; 
    clip-path: circle(100%);
  }
`;

function Cover() {
  return (
    <>    

    <MKBox  className="bannerContainer bannerSubmission"
      sx={{
        backgroundImage: `url(${bgImage})`,
      }}
    >

      <MKTypography className="bannerText bannerSlashText">
        /
      </MKTypography>
      <MKTypography className="bannerText bannerRevealText">
        <MKTypography className="bannerText bannerSubmissionText">
          SUBMISSION
        </MKTypography>
      </MKTypography>
    </MKBox>

  </>      
        
  );
}

export default Cover;
