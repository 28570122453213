import MKBox from "components/MKBox";
import bgImage from "assets/images/project/model_cover_wall.png";
import MKTypography from "components/MKTypography";

function Cover() {
  return (
    <>    
      <MKBox className="bannerContainer">
        <MKTypography className="bannerCoverImg bannerActorTopLeft">       
          <img src={bgImage}  alt="cover_img" />
        </MKTypography>
        <MKTypography className="bannerCoverImg bannerActorBottomRight">
          <img src={bgImage} alt="cover_img" />
        </MKTypography>      
        <MKTypography className="bannerText bannerSlashText">
          /
        </MKTypography>
        <MKTypography className="bannerText bannerRevealText">
          <MKTypography className="bannerText bannerNameText">
            ACTORS
          </MKTypography>
        </MKTypography>
      </MKBox>
    </>      
  );
}

export default Cover;
