import Grid from "@mui/material/Grid";
import {Card, CardMedia, CardContent} from "@mui/material";
import 'project/index.css';
import MKBox from "components/MKBox";
import {Container} from "@mui/material";
import MKTypography from "components/MKTypography";

function BrandLists({items}) {

    return(
        <MKBox component="section" py={12} style={{ backgroundColor: "white", boxShadow: '0px 2px 15px 0px rgba(0, 0, 0, 0.5)', minHeight: '75vh' }}>
            {items &&(
                <Container>
                    <Grid container spacing={3}>
                        {items.map((item, index) => (
                        <Grid item xs={12} sm={12} md={6} lg={3} key={index}>

                            <a href={item.video_link} target="blank">
                                <MKBox
                                    alt={item.name}
                                    className="galleryImages"
                                    sx={{
                                        backgroundImage: `url("${process.env.REACT_APP_IMG_URL + decodeURIComponent(item.logo)}")`,
                                        backgroundSize: 'cover',
                                        aspectRatio: '4/4',
                                        borderRadius: "20px",
                                    }}
                                >
                                </MKBox>
                                <MKTypography textAlign="center">{item.name}</MKTypography>
                            </a>
                        </Grid>
                        ))}
                    </Grid>
                </Container>
            )}
        </MKBox>
    );
}

export default BrandLists;