import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import ProfileCover from "../../Actors/Layout/profileCover";
import Gallery from './gallery2';
import ProfileDetails from "./profileDetails";
import { useFetchId } from 'shared libraries/tools';
import {actorDetails} from 'project/APIs/apis';

function Profile() {
    const id = useFetchId();
    const [actorData, setActorData] = useState(null);
    const [actorImages, setActorImages] = useState(null);

    useEffect(() => {

        const element = document.querySelector('#target-id');
        if (element) {
            element.scrollIntoView({ behavior: 'smooth', block: 'end' });
        }
        const fetchActorData = async () =>{
            try{
                const response = await actorDetails(id);
                if(response.data.hasOwnProperty("response_code") && response.data.success){
                    setActorData(response.data.data.details);
                    setActorImages(response.data.data.images);
                }
            }catch(error){
                
            }
        };
        fetchActorData();
    }, []);
    
    return (
        <>
        <ProfileCover actorImages={actorImages}></ProfileCover>
        <ProfileDetails actorData={actorData}></ProfileDetails>
        <Gallery actorImages={actorImages} id={id}></Gallery>
        </>
    );
}

export default Profile;
