import { useState } from "react";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";
import Cover from "./Layouts/cover";
import Details from "./Layouts/details";

function FormSimple() {

  return (
    <>
    <Cover></Cover>
    <Details></Details>
    </>
  );
}

export default FormSimple;