import React from 'react';
import PropTypes from 'prop-types';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import MKBox from 'components/MKBox';
import MKTypography from 'components/MKTypography';

// Define the CenteredFooter component using default parameters
function CenteredFooter({
  company = { name: "Casting Beans Talents Management. All rights" },
  links = [
    { href: "/work-profile", name: "Work Profile" },
    { href: "/actors", name: "Actors" },
    { href: "/contact-us", name: "Contact Us" },
  ],
  socials = [
    {
      icon: <FacebookIcon fontSize="small" />,
      link: "https://www.facebook.com/pages/category/Arts---Entertainment/Casting-Beans-642656899494216/"
    },
    {
      icon: <InstagramIcon fontSize="small" />,
      link: "https://www.instagram.com/castingbeans/?hl=en",
    },
  ],
  light = false,
}) {
  const { name, href } = company;

  const year = new Date().getFullYear();

  const renderLinks = links.map((link) => (
    <MKTypography
      key={link.name}
      component={Link}
      href={link.href}
      variant="body2"
      color={light ? "white" : "secondary"}
      fontWeight="regular"
    >
      {link.name}
    </MKTypography>
  ));

  const renderSocials = socials.map((social) => (
    <MKTypography
      key={social.link}
      component={Link}
      href={social.link}
      target="_blank"
      variant="body2"
      color="white"
      fontWeight="regular"
    >
      {social.icon}
    </MKTypography>
  ));

  return (
    <MKBox
      component="footer"
      py={1}
      sx={{
        backgroundColor: 'rgba(0,0,0,0.9)',
        boxShadow: '0px -10px 6px -1px rgba(0, 0, 0, 0.3)',
      }}
    >
      <Grid container justifyContent="center">
        {/* <Grid item xs={10} lg={8}>
          <Stack
            direction="row"
            flexWrap="wrap"
            justifyContent="center"
            spacing={{ xs: 2, lg: 3, xl: 6 }}
            mb={3}
          >
            {renderLinks}
          </Stack>
        </Grid> */}
        <Grid item xs={12} lg={8}>
          <Stack display="flex" direction="row" justifyContent="center" spacing={1} mt={1} mb={0}>
            {renderSocials}
          </Stack>
        </Grid>
        <Grid item xs={12} lg={8} sx={{ textAlign: 'center' }}>
          <MKTypography variant="caption" color="white">
            Copyright &copy; {year}{" "}
            <MKTypography
              component={Link}
              href={href}
              target="_blank"
              rel="noreferrer"
              variant="caption"
              color="white"
            >
              {name}
            </MKTypography>
            .
          </MKTypography>
        </Grid>
      </Grid>
    </MKBox>
  );
}

// Typechecking props for the CenteredFooter
CenteredFooter.propTypes = {
  company: PropTypes.shape({
    name: PropTypes.string.isRequired,
    href: PropTypes.string, // Adjust according to your actual structure if needed
  }),
  links: PropTypes.arrayOf(
    PropTypes.shape({
      href: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    })
  ),
  socials: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.element.isRequired,
      link: PropTypes.string.isRequired,
    })
  ),
  light: PropTypes.bool,
};

export default CenteredFooter;
