import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton";
import SubmissionLogo from "assets/images/project/submission.jpg";

function FeaturesOne() {
  return (
    <MKBox component="section" py={{ xs: 3, md: 12 }} style={{backgroundColor: "white", boxShadow: '0px 2px 15px 0px rgba(0, 0, 0, 0.5)'}}>
        <Container>
            <Grid container alignItems="center">
                <Grid item xs={12} lg={6} sx={{mt: { xs: 6, lg: 0 }, order: { xs: 2, lg: 1 }}}>
                    <MKTypography variant="h3" my={1}>
                        SUBMISSION
                    </MKTypography>
                    <MKTypography variant="body2" color="text" mb={2}>
                        Submit your details to join our roster of talented models. Fill out the application form with your personal information, measurements, and portfolio highlights. Providing comprehensive and accurate details will help us showcase your unique talents and connect you with the right opportunities. Once we review your submission, our team will reach out with further information and next steps
                    </MKTypography>
                    <MKButton className="sectionBtn"
                        component="a"
                        href="/submission"
                        variant="outlined" 
                        color="secondary" 
                        size="large" 
                    >
                        Form Submit <Icon sx={{ ml: 1 }}>arrow_forward</Icon>
                    </MKButton>
                </Grid>
                <Grid item xs={12} lg={5} sx={{ ml: { xs: 0, lg: "auto" }, mt: { xs: 0, lg: 0 }, order: { xs: 1, lg: 2 } }}>
                    <MKBox className="sectionImg"
                        component="img" 
                        src={SubmissionLogo} 
                        alt="submission logo" 
                    />
                </Grid>
            </Grid>
        </Container>
    </MKBox>
  );
}

export default FeaturesOne;