import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import MKAvatarRoot from 'components/MKAvatar/MKAvatarRoot';

// Define the MKAvatar component using default parameters
const MKAvatar = forwardRef(
  ({ bgColor = "transparent", size = "md", shadow = "none", ...rest }, ref) => (
    <MKAvatarRoot
      ref={ref}
      ownerState={{ bgColor, size, shadow }}
      {...rest}
    />
  )
);

// Typechecking props for MKAvatar
MKAvatar.propTypes = {
  bgColor: PropTypes.oneOf([
    "transparent",
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "light",
    "dark",
  ]),
  size: PropTypes.oneOf(["xs", "sm", "md", "lg", "xl", "xxl"]),
  shadow: PropTypes.oneOf(["none", "xs", "sm", "md", "lg", "xl", "xxl", "inset"]),
};

export default MKAvatar;
