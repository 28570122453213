    import React, { useEffect, useState } from "react";
    import Grid from "@mui/material/Grid";
    import {Card, CardMedia, CardContent} from "@mui/material";
    import 'project/index.css';
    import MKBox from "components/MKBox";
    import MKTypography from "components/MKTypography";
    import { useDispatch } from 'react-redux';
    import { setActorId } from '../../../redux/slices/actorSlice';
    import { useNavigate } from 'react-router-dom';
    import {actorListing} from 'project/APIs/apis';
    import { keyframes } from '@emotion/react';
    import { transparentize } from 'polished';
    import EmptyImage from 'assets/images/project/grid_bg.png';
    import { useTheme } from '@mui/material/styles';
    import { Helmet } from 'react-helmet';

    function Listing({data}) {
        const [items,setItems] = useState(null);
        const [backgroundImage, setBackgroundImage] = useState(null);
        const dispatch = useDispatch();
        const navigate = useNavigate();
        const theme = useTheme();

        const rotateY = keyframes`
            0% {
                transform: rotateY(90deg);
            }
            100% { 
                transform: rotateY(00deg);
            }
        `;

        useEffect(() =>  {
            const fetchData = async () =>{
                try{
                    const params = data == 0 ? 'female' : 'male';
                    const response = await actorListing(params);

                    if(response.data.hasOwnProperty("response_code") && response.data.success){
                        setItems(response.data.data);
                    }
                }catch(error){

                }
            };
            fetchData();

        },[]);

        const viewProfie = (data,id) => () => {
            // dispatch(setActorId(id));
            data = data.replace(' ','-');
            window.scrollTo(0,0);
            navigate(`/actors/profile/${data}/${id}`);
        };

        const handleError = (event) => {
            event.target.src = EmptyImage;
        };
        

        return (
            <>
            <Helmet>
                <title>Casting Beans | Actors | Model | Female Model | Casting Beans | Talents Management</title>
            </Helmet>

            {items &&(
                <Grid container spacing={3} >
                    {items.map((item, index) =>(
                        <Grid item xs={12} sm={4} md={4} lg={3} key={index} >
                            <MKBox 
                                mb={1}
                                component="a"
                                onClick={viewProfie(item.name,item.actor_ref_no)}
                                //href={/actors/profile/${item.value}} 
                                sx={{ 
                                    cursor: 'pointer',
                                }}
                                >                   
                                <MKBox 
                                    component="img"
                                    src={process.env.REACT_APP_IMG_URL + decodeURIComponent(item.profile_image)}
                                    alt={item.name}
                                    sx={{
                                        width: '100%',
                                        animation: `${rotateY} 1s forwards`,
                                        objectFit: 'cover',
                                        height: {xs: "280px", sm:'140px', md:'190px', lg: "220px", xl: "240px"},
                                        transition: 'transform 0.3s ease-in-out, filter 0.3s ease-in-out',
                                        '&:hover': {
                                            transform: 'scale(1.05)',
                                            filter: 'brightness(0.7)',
                                        },
                                    }}
                                />
                                <MKTypography 
                                    color="black" 
                                    component="div" 
                                    sx={{ 
                                        animation: `${rotateY} 1s forwards`,
                                        mt: 0,
                                        [theme.breakpoints.up('xs')]: {
                                            typography: 'h4',    
                                            fontWeight: "bold",
                                            color: "black",
                                        },
                                        [theme.breakpoints.up('sm')]: {
                                            typography: 'h6',    
                                            fontWeight: "bold",
                                            color: "black",
                                        },
                                        [theme.breakpoints.up('md')]: {
                                            typography: 'h5',    
                                            fontWeight: "bold",
                                            color: "black",
                                        },
                                        [theme.breakpoints.up('lg')]: {
                                            typography: 'h5',
                                            fontWeight: "bold",
                                            color: "black",
                                        },
                                        [theme.breakpoints.up('xl')]: {
                                            typography: 'h5',
                                            fontWeight: "bold",
                                            color: "black",
                                        },
                                    }}
                                >
                                    {item.name}
                                </MKTypography>
                            </MKBox>
                        </Grid>
                    ))};
                </Grid>
            )}
            </>
        );
    }

    export default Listing;