import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  actorId: null,
};

const actorSlice = createSlice({
  name: 'actor',
  initialState, 
  reducers: {
    // Define your actions and reducers here
    setActorId(state, action) {
      state.actorId = action.payload;
    },
  },
});

export const { setActorId } = actorSlice.actions;
export default actorSlice.reducer;
