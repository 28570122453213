import Cover from "./Layouts/cover";
import Videos from "./Layouts/videos";
import { useParams } from 'react-router-dom';
import { workProfileListing } from "project/APIs/apis";
import { useEffect, useState } from "react";
import BrandLists from "./Layouts/brandsLists";

function WorkProfile() {
  const { name } = useParams();
  
  const [items, setItems] = useState();

  useEffect(() =>  {
    setItems();
    const fetchData = async () =>{
        try{
          const response = await workProfileListing(name);
          if(response.data.hasOwnProperty("response_code") && response.data.success){
            setTimeout(() => {
              setItems(response.data.data);
            }, 1000); 
          }
        }catch(error){

        }
    };
    fetchData();
  },[name]);

  
  return (
    <>
    <Cover></Cover>
    {(name === 'films' || name === 'tv-series' || name === 'web-series' || name === 'ads')  &&(
      <Videos items={items}></Videos>
    )}
   {name === 'influencer' &&(
      <BrandLists items={items}></BrandLists>
    )}
    </>
  );
}

export default WorkProfile;