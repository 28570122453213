import ModelSection from "project/HomePage/Layouts/modelSection";
import WorkProfile from "project/HomePage/Layouts/workProfile";
import ContactUsSection from "project/HomePage/Layouts/contactSection";
import SubmissionSection from "project/HomePage/Layouts/submissionSection";
import Banner from "project/HomePage/Layouts/banner";

function Home() {

  return (
        <div>
            <Banner></Banner>
            <ModelSection></ModelSection>
            <WorkProfile></WorkProfile>
            <ContactUsSection></ContactUsSection>
            <SubmissionSection></SubmissionSection> 
        </div>

  );
}

export default Home;
