import React from 'react';
import { useParams } from 'react-router-dom';

import {  toast } from 'react-toastify';

  const toastConfig = {
    position: "top-right",
    autoClose: 6000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    theme: "colored",
  };

const toastList = new Set();
const MAX_TOAST = 1;

export const getQueryParam = (query,skipKey=false) => {
    let serachUrl = '?';
    for (const key in query) {
      if (query.hasOwnProperty(key)) {
        if (query[key] && query[key].length > 0) {
          if(key==="selected" && query['search'] && !skipKey){
            serachUrl += `${query['selected']}=${query['search']}&`;
          }else if((key!=="search" &&key!=="selected")|| skipKey){
            serachUrl += `${key}=${query[key]}&`;
          }
        }
      }
    }
    return serachUrl;
};

export const useFetchId = () => {
    const { id } = useParams();
    return id;
}

export const capitalizeFirstLetter = (sentence) => {
    if (!sentence) return '';
    return sentence
    .split(' ')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};

export const toastSuccess = (message) => {
  if (toastList.size < MAX_TOAST) {
    const id = toast.success(message, {
      ...toastConfig,
      onClose: () => toastList.delete(id),
    });
    toastList.add(id);
  }
};

export const toastInfo = (message) => {
  if (toastList.size < MAX_TOAST) {
    const id = toast.info(message, {
      ...toastConfig,
      onClose: () => toastList.delete(id),
    });
    toastList.add(id);
  }
};

export const toastWarning = (message) => {
  if (toastList.size < MAX_TOAST) {
    const id = toast.warn(message, {
      ...toastConfig,
      onClose: () => toastList.delete(id),
    });
    toastList.add(id);
  }
};

export const toastError = (message) => {

  if (toastList.size < MAX_TOAST) {
    const id = toast.error(message, {
      ...toastConfig,
      onClose: () => toastList.delete(id),
    });
    toastList.add(id);
  }
};



