import { configureStore } from "@reduxjs/toolkit";
import actorReducer from "../slices/actorSlice";

const store = configureStore({
    reducer: {
        actor: actorReducer,
    },
});

export default store;
