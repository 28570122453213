import React from "react";
import * as ReactDOMClient from "react-dom/client";
import App from "App";
import store from "./redux/store"; 
import { Provider } from "react-redux";
import 'project/index.css';
// Toast
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const container = document.getElementById("root");

// Create a root.
const root = ReactDOMClient.createRoot(container);

root.render(
    <Provider store={store}>
        <App />
        <ToastContainer />
    </Provider>
    
);
